import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './NewsLetter.module.scss';
import Button from '../Button';

const NewsLetter = ({ title, text, modifier, countries = [] }) => {
    const { t } = useTranslation();

    const [country, setCountry] = useState('');
    const selectedCountry = countries.find((c) => c.value === country);

    const classes = classNames(styles['NewsLetter'], {
        [styles[`NewsLetter--${modifier}`]]: modifier,
    });

    if (!title) {
        return null;
    }

    return (
        <div className={classes}>
            <div className={styles['NewsLetter__Wrapper']}>
                <div className={styles['NewsLetter__Column']}>
                    <h2 className={styles['NewsLetter__Title']}>{title}</h2>
                    {text && (
                        <p className={styles['NewsLetter__Text']}>{text}</p>
                    )}
                </div>
                <div className={styles['NewsLetter__Column']}>
                    {!!countries && (
                        <div className={styles['NewsLetter__Select']}>
                            <label>
                                <select
                                    onChange={(e) => setCountry(e.target.value)}
                                    name="countries"
                                    className={styles['NewsLetter__Input']}
                                >
                                    <option value="">
                                        {t('newsLetter.placeholder')}
                                    </option>
                                    {countries.map((item, index) => (
                                        <option value={item.value} key={index}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                                <span className="sr-only">
                                    Sign up for newsletter
                                </span>
                            </label>
                        </div>
                    )}
                    <div className={styles['NewsLetter__ButtonContainer']}>
                        <Button
                            link={selectedCountry?.href || null}
                            text={t('newsLetter.submit')}
                            icon={'/svg/icon-arrow.svg'}
                            type="Primary"
                            style={{ padding: '19px 24px' }}
                            disabled={!selectedCountry?.href}
                            attrs={{
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

NewsLetter.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    modifier: PropTypes.string,
};

NewsLetter.defaultProps = {};

export default NewsLetter;
