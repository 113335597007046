import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './GlobalSiteSwitcher.module.scss';

const GlobalSiteSwitcher = ({ label, links }) => {
    const [open, setOpen] = useState(false);

    if (!links.length) {
        return null;
    }

    return (
        <div className={styles['GlobalSiteSwitcher']}>
            <div className={styles['GlobalSiteSwitcher__Content']}>
                <button
                    className={styles['GlobalSiteSwitcher__LabelContainer']}
                    onClick={() => setOpen(!open)}
                >
                    <span className={styles['GlobalSiteSwitcher__Label']}>
                        {label}
                    </span>
                    <div
                        className={classNames(
                            styles['GlobalSiteSwitcher__Arrow'],
                            {
                                [styles['GlobalSiteSwitcher__Arrow--Open']]:
                                    open,
                            }
                        )}
                    />
                </button>
                <ul
                    className={classNames(styles['GlobalSiteSwitcher__Items'], {
                        [styles['GlobalSiteSwitcher__Items--Open']]: open,
                    })}
                >
                    {links.map(({ value }, i) => (
                        <li key={i}>
                            <a
                                className={styles['GlobalSiteSwitcher__Link']}
                                href={value.href}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {value.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

GlobalSiteSwitcher.defaultProps = {
    label: '',
    links: [],
};

export default GlobalSiteSwitcher;
